
.PublicAppPagePrimaryImageContainer {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.PublicAppPagePrimaryContentContainer {
  position: absolute;
  top: 45px;        /*under navbar*/
  /*height: 600px;*/
  width: 100%;    /*vw*/
  z-index: 100;
}
.PublicAppPagePrimaryContent {
  position: relative;
  width: 80vw;
  background-color: #fff;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
}

.PublicAppPagePrimaryContent_head {
  text-align:center;
  padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
}
.PublicAppPagePrimaryContent_body {

}
.PublicAppPagePrimaryContent_foot {
  margin-top:20px;
  border-top:1px solid #ddd;
}





.mobileAppLinks_inlineMax200 {
  width: 100%;
  height: auto;
  max-width: 200px;
}



/***************** HOME PAGE INFO PANELS ***********/


.bannerTagLine {
  font-weight: bold;
  line-height: 1em;
  text-transform: uppercase;
  z-index: 4;
  width: 75%;
}

.bannerTagLine span {
  background: #1972ba;
  display: inline-block;
  zoom: 1;
  float: left;
  color: #fff;
  padding: 0.1em 0.2em;
  margin: 0.05em 0;
}














/******* HEADER GRID *********************************/


.webImageSettings {
  width: 100%;
  height: auto;
}
@media only screen and (max-width: 230px) {
  .bannerTagLine {
    font-size: 16px;
  }
  .webImageSettings {
    width: 900%;
    height: auto;
  }
  .contentContainerSmallScreenColumnReverse {
    flex-direction: column-reverse;
  }
  .contentContainerSmallScreenColumn {
    flex-direction: column;
  }
  .PublicAppPagePrimaryContentContainer {
    top: 5px; 
  }
  .PublicAppPage100vhContainer {
    background-color: #196090;
    height: 30vh;
    min-height: 460px;  /* NEW */
    width: 100%;
    position: relative;
  }
  .PublicAppPagePrimaryImageContainer {
    opacity: 0.2
  }
  .primaryHeaderGrid {
    display: grid;
    grid-template-columns: 20px auto 20px;
    grid-template-rows: auto;
  }
  .primaryHeader_title {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-end: 1;
    color: #fff;
    font-size: 22px;
    padding: 10px;
  }
  .primaryHeader_subTitle {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-end: 2;
    color: #fff;
    font-size: 12px;
    padding: 10px;
  }
  .primaryHeader_appLinks {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-end: 3;
    color: #fff;
    font-size: 20px;
    /*padding: 10px;*/
  }
  .primaryHeader_appLinkContainer {
    height: 170px;
    max-height: 170px;
  }
}
@media only screen and (min-width: 230px) and (max-width: 330px) {
  .bannerTagLine {
    font-size: 20px;
  }
  .webImageSettings {
    width: 600%;
    height: auto;
  }
  .contentContainerSmallScreenColumnReverse {
    flex-direction: column-reverse;
  }
  .contentContainerSmallScreenColumn {
    flex-direction: column;
  }
  .PublicAppPage100vhContainer {
    background-color: #196090;
    height: 40vh;
    min-height: 460px;  /* NEW */
    width: 100%;
    position: relative;
  }
  .PublicAppPagePrimaryImageContainer {
    opacity: 0.2
  }
  .primaryHeaderGrid {
    display: grid;
    grid-template-columns: 20px auto 20px;
    grid-template-rows: auto;
  }
  .primaryHeader_title {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-end: 1;
    color: #fff;
    font-size: 22px;
    padding-bottom: 10px;
  }
  .primaryHeader_subTitle {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-end: 2;
    color: #fff;
    font-size: 14px;
    padding-bottom: 10px;
  }
  .primaryHeader_appLinks {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-end: 3;
    color: #fff;
    font-size: 20px;
    /*padding-bottom: 10px;*/
  }
  .primaryHeader_appLinkContainer {
    height: 170px;
    max-height: 170px;
  }
}
@media only screen and (min-width: 330px) and (max-width: 430px) {
  .bannerTagLine {
    font-size: 30px;
  }
  .webImageSettings {
    width: 400%;
    height: auto;
  }
  .contentContainerSmallScreenColumnReverse {
    flex-direction: column-reverse;
  }
  .contentContainerSmallScreenColumn {
    flex-direction: column;
  }
  .PublicAppPage100vhContainer {
    background-color: #196090;
    height: 50vh;
    width: 100%;
    position: relative;
  }
  .PublicAppPagePrimaryImageContainer {
    opacity: 0.2
  }
  .primaryHeaderGrid {
    display: grid;
    grid-template-columns: 20px auto 20px;
    grid-template-rows: auto;
  }
  .primaryHeader_title {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-end: 1;
    color: #fff;
    font-size: 22px;
    padding-bottom: 30px;
  }
  .primaryHeader_subTitle {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-end: 2;
    color: #fff;
    font-size: 16px;
    padding-bottom: 30px;
  }
  .primaryHeader_appLinks {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-end: 3;
    color: #fff;
    font-size: 20px;
    /*padding-bottom: 10px;*/
  }
  .primaryHeader_appLinkContainer {
    height: 170px;
    max-height: 170px;
  }
}
@media only screen and (min-width: 430px) and (max-width: 530px) {
  .bannerTagLine {
    font-size: 36px;
  }
  .webImageSettings {
    width: 300%;
    height: auto;
  }
  .contentContainerSmallScreenColumnReverse {
    flex-direction: column-reverse;
  }
  .contentContainerSmallScreenColumn {
    flex-direction: column;
  }
  .PublicAppPage100vhContainer {
    background-color: #196090;
    height: 50vh;
    width: 100%;
    position: relative;
  }
  .PublicAppPagePrimaryImageContainer {
    opacity: 0.2
  }
  .primaryHeaderGrid {
    display: grid;
    grid-template-columns: 20px auto 20px;
    grid-template-rows: auto;
  }
  .primaryHeader_title {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-end: 1;
    color: #fff;
    font-size: 28px;
    padding-bottom: 20px;
  }
  .primaryHeader_subTitle {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-end: 2;
    color: #fff;
    font-size: 20px;
    padding-bottom: 30px;
  }
  .primaryHeader_appLinks {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-end: 3;
    color: #fff;
    font-size: 20px;
    /*padding-bottom: 30px;*/
  }
  .primaryHeader_appLinkContainer {
    height: 120px;
    max-height: 120px;
  }
}
@media only screen and (min-width: 530px) and (max-width: 700px) {
  .bannerTagLine {
    font-size: 40px;
  }
  .webImageSettings {
    width: 250%;
    height: auto;
  }
  .contentContainerSmallScreenColumnReverse {
    flex-direction: column-reverse;
  }
  .contentContainerSmallScreenColumn {
    flex-direction: column;
  }
  .PublicAppPage100vhContainer {
    background-color: #196090;
    height: 50vh;
    width: 100%;
    position: relative;
  }
  .PublicAppPagePrimaryImageContainer {
    opacity: 0.2
  }
  .primaryHeaderGrid {
    display: grid;
    grid-template-columns: 40px auto 40px;
    grid-template-rows: auto;
  }
  .primaryHeader_title {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-end: 1;
    color: #fff;
    font-size: 30px;
    padding-bottom: 30px;
  }
  .primaryHeader_subTitle {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-end: 2;
    color: #fff;
    font-size: 20px;
    padding-bottom: 30px;
  }
  .primaryHeader_appLinks {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-end: 3;
    color: #fff;
    font-size: 20px;
    /*padding-bottom: 30px;*/
  }
  .primaryHeader_appLinkContainer {
    height: 120px;
    max-height: 120px;
  }
}
@media only screen and (min-width: 700px) and (max-width: 830px) {
  .bannerTagLine {
    font-size: 40px;
  }
  .webImageSettings {
    width: 180%;
    height: auto;
  }
  .contentContainerSmallScreenColumnReverse {
    flex-direction: column-reverse;
  }
  .contentContainerSmallScreenColumn {
    flex-direction: column;
  }
  .PublicAppPage100vhContainer {
    background-color: #196090;
    height: 50vh;
    width: 100%;
    position: relative;
  }
  .PublicAppPagePrimaryImageContainer {
    opacity: 0.2
  }
  .primaryHeaderGrid {
    display: grid;
    grid-template-columns: 30% auto 40px;
    grid-template-rows: auto;
  }
  .primaryHeader_title {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-end: 1;
    color: #fff;
    font-size: 30px;
    padding-bottom: 30px;
  }
  .primaryHeader_subTitle {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-end: 2;
    color: #fff;
    font-size: 20px;
    padding-bottom: 30px;
  }
  .primaryHeader_appLinks {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-end: 3;
    color: #fff;
    font-size: 20px;
    /*padding-bottom: 30px;*/
  }
  .primaryHeader_appLinkContainer {
    height: 120px;
    max-height: 120px;
  }
}
@media only screen and (min-width: 830px) and (max-width: 1060px) {
  .bannerTagLine {
    font-size: 40px;
  }
  .webImageSettings {
    width: 150%;
    height: auto;
  }
  .contentContainerSmallScreenColumnReverse {
    flex-direction: row;
  }
  .contentContainerSmallScreenColumn {
    flex-direction: row;
  }
  .PublicAppPage100vhContainer {
    background-color: #196090;
    height: 50vh;
    width: 100%;
    position: relative;
  }
  .PublicAppPagePrimaryImageContainer {
    opacity: 0.2
  }
  .primaryHeaderGrid {
    display: grid;
    grid-template-columns: 40% auto 40px;
    grid-template-rows: auto;
  }
  .primaryHeader_title {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-end: 1;
    color: #fff;
    font-size: 30px;
    padding-bottom: 30px;
  }
  .primaryHeader_subTitle {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-end: 2;
    color: #fff;
    font-size: 20px;
    padding-bottom: 30px;
  }
  .primaryHeader_appLinks {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-end: 3;
    color: #fff;
    font-size: 20px;
    /*padding-bottom: 30px;*/
  }
  .primaryHeader_appLinkContainer {
    height: 120px;
    max-height: 120px;
  }
}

@media only screen and (min-width: 1060px) and (max-width: 1250px) {
  .bannerTagLine {
    font-size: 40px;
  }
  .webImageSettings {
    width: 120%;
    height: auto;
  }
  .contentContainerSmallScreenColumnReverse {
    flex-direction: row;
  }
  .contentContainerSmallScreenColumn {
    flex-direction: row;
  }
  .PublicAppPage100vhContainer {
    background-color: #3498db;
    height: 50vh;
    width: 100%;
    position: relative;
  }
  .PublicAppPagePrimaryImageContainer {
    opacity: 0.8
  }
  .primaryHeaderGrid {
    display: grid;
    grid-template-columns: 52% auto 40px;
    grid-template-rows: auto;
  }
  .primaryHeader_title {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-end: 1;
    color: #fff;
    font-size: 30px;
    padding-bottom: 30px;
  }
  .primaryHeader_subTitle {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-end: 2;
    color: #fff;
    font-size: 20px;
    padding-bottom: 30px;
  }
  .primaryHeader_appLinks {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-end: 3;
    color: #fff;
    font-size: 20px;
    /*padding-bottom: 30px;*/
  }
  .primaryHeader_appLinkContainer {
    height: 120px;
    max-height: 120px;
  }
}

@media only screen and (min-width: 1250px) {
  .bannerTagLine {
    font-size: 40px;
  }
  .webImageSettings {
    width: 100%;
    height: auto;
  }
  .contentContainerSmallScreenColumnReverse {
    flex-direction: row;
  }
  .contentContainerSmallScreenColumn {
    flex-direction: row;
  }
  .PublicAppPage100vhContainer {
    background-color: #3498db;
    height: 50vh;
    width: 100%;
    position: relative;
  }
  .PublicAppPagePrimaryImageContainer {
    opacity: 0.8
  }
  .primaryHeaderGrid {
    display: grid;
    grid-template-columns: 52% auto 40px;
    grid-template-rows: auto;
  }
  .primaryHeader_title {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-end: 1;
    color: #fff;
    font-size: 30px;
    padding-bottom: 30px;
  }
  .primaryHeader_subTitle {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-end: 2;
    color: #fff;
    font-size: 20px;
    padding-bottom: 30px;
  }
  .primaryHeader_appLinks {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-end: 3;
    color: #fff;
    font-size: 20px;
    /*padding-bottom: 30px;*/
  }
  .primaryHeader_appLinkContainer {
    height: 120px;
    max-height: 120px;
  }
}


.contentEdgeToEdgeContainer {
  width: 100vw;
}


.contentContainerImageMax450 {
  max-height: 450px;
  overflow: hidden;
}
.contentContainerRoundedImageOnLeft {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}


.gradientBg {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
}
@keyframes gradient {
  0% {
      background-position: 0% 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0% 50%;
  }
}

